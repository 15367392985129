import React, { useState, useEffect } from 'react';

const SessionPopup = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const hasSeenPopup = sessionStorage.getItem('hasSeenPopup');
        if (!hasSeenPopup) {
            setIsVisible(true);
            sessionStorage.setItem('hasSeenPopup', 'true');
        }
    }, []);

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="relative p-4 bg-white rounded-lg max-w-[90%] md:max-w-[600px]">
                <button
                    onClick={handleClose}
                    className="absolute p-2 text-white bg-perfetto-olive border-2 border-white rounded-full -top-5 -right-5 hover:text-white hover:bg-gray-900 shadow-md z-10"
                >
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
                <img
                    src="/img/babka-perfetto.jpeg" // Replace with your image path
                    alt="Popup"
                    className="w-full rounded-lg"
                />
            </div>
        </div>
    );
};

export default SessionPopup; 